// Header

#top,
#bottom
  height: 100px
  margin: 0 24px
  +v-xs
    margin: 16px
    height: auto
#top
  margin: 20px
  +v-xs
    margin: -16px 24px 40px 0
#bottom
  height: 64px
  +v-xs
    display: block
    padding-bottom: 40px !important
    justify-content: center !important
    p
      margin-bottom: 16px
  *
    color: $grey
    +v-xs
      text-align: center !important

.content
  margin: 32px
  +v-xs
    margin: 16px
