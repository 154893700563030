/* Base */

html
  font-family: $basic
  font-weight: 300
  font-size: 1rem
  font-smooth: always
  -webkit-font-smoothing: antialised
body
  padding: 0
  color: $white
  font-size: 95%
  font-family: $basic
  font-weight: 300
  background: $white
  background-size: 100%
  max-width: 100%
  overflow-x: hidden
  &.fix-mob
    +v-sm
      overflow: hidden
  a
    text-decoration: none
    cursor: pointer !important
  input,
  button
    border: none
    outline: none

  p
    font-size: 1em
    font-weight: 400 !important
    line-height: 1.75

.form
  padding: 24px
  border-radius: 16px
  border: 2px solid $marine
  .form-control
    +placeholder
      font-size: 14px !important
      color: $grey !important
  select
    font-size: 14px !important
    color: $grey !important

.container
  width: 90%
  max-width: 1136px
  margin: 32px
  margin-left: 5%
  margin-right: 5%
  border-radius: 16px
  border: 2px solid $light
  +v-xs
    border: none !important
    zoom: .9
