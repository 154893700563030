/* Variables */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

//Colors
$red: #FA7375
$light: #EDEDED
$lite: #d9d9d9
$grey: #B9B9B9
$pink: #FE3E87
$dark: #282828
$blue: #07619B
$card: #1080C8
$theme: #079DDD
$marine: #13DBE8
$white: #fff
$black: #000

// Fonts
$basic: 'Poppins', sans-serif !important

// animation
.au
  animation: Up 1.4s .4s
.ad
  animation: Down 1.4s .4s
.al
  animation: Left 1.7s .4s
.ar
  animation: Right 1.7s .4s

@keyframes Right
  from
    transform: translateX(60px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Left
  from
    transform: translateX(-60px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Up
  from
    transform: translateY(20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

@keyframes Down
  from
    transform: translateY(-20px)
    opacity: 0
  to
    transform: translateY(0)
    opacity: 1

// Placeholder
=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
