@import "inc/variables"
@import "inc/reset"
@import "inc/responsive"
@import "components/base"
@import "components/header"
@import "components/process"
@import "components/reviews"
@import "elements/buttons"
@import "elements/icons"
@import "inc/helpers"
@import "elements/_tooltip"

.btn
  border: none !important
  outline: none !important

.hidden
  display: none !important

.slick-slide
  &[data-index="0"],
  &[data-index="4"],
  &[data-index="8"],
  &[data-index="12"]
    .it-card
      background-color: $marine
  &[data-index="1"],
  &[data-index="5"],
  &[data-index="9"],
  &[data-index="13"]
    .it-card
      background-color: $theme
  &[data-index="2"],
  &[data-index="6"],
  &[data-index="10"],
  &[data-index="14"]
    .it-card
      background-color: $card
  &[data-index="3"],
  &[data-index="7"],
  &[data-index="11"],
  &[data-index="15"]
    .it-card
      background-color: $blue
.it-container
  width: 334px
  height: 296px
  overflow: hidden
  border-radius: 8px !important

.it-card
  display: flex
  flex-flow: row wrap
  justify-content: space-between
  height: 296px
  margin-left: -2px
  margin-right: 2px
  border-radius: 8px !important
  overflow: hidden
  z-index: 1
  .it-content
    width: 0
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 16px 0 24px 16px !important
    margin: 0 0 0 -32px !important
    height: 296px !important
    text-align: left !important
    border-radius: 8px !important
    overflow: hidden
    opacity: 0
    transition: all ease-out .5s
    z-index: -1
    .it-p,
    .it-h4,
    .it-h5
      width: 164px !important
      min-width: 164px !important
      max-width: 164px !important
    h4,
    h5,
    p
      margin-right: 16px
      color: $white
      font-size: 16px !important
      font-weight: bold !important
      text-align: left !important
    p
      font-weight: normal !important
    h4
      padding-top: 20px
      font-size: 14px
      text-transform: uppercase
  &:hover
    .it-content
      margin: 0 0 0 -16px !important
      opacity: 1
      z-index: 999
      width: 190px
      border-radius: 8px !important
  .it-mini-card
    width: 84px
    height: 296px
    display: flex
    flex-direction: column
    justify-content: space-between
    h4
      text-align: center !important
      margin-top: 16px
    img
      width: 56px
      height: 56px
      margin: 16px auto
  h4
    font-size: 24px !important

.slick-slide
  *
    transition: all ease-out .5s
  .it-card
    position: relative
    z-index: 0

.slick-active.slick-current + .slick-active
  .it-card
    &:hover
      margin-left: -86px

.slick-active.slick-current + .slick-active + .slick-active
  .it-card
    &:hover
      margin-left: -86px
.slick-active.slick-current + .slick-active + .slick-active + .slick-active
  .it-card
    &:hover
      margin-left: -172px

.col-it-left
  margin-left: -20px
  margin-right: 20px

.col-it-right
  margin-right: -20px
  margin-left: 20px

.col-it-center
  +v-xs
    margin-top: 40px !important
    margin-bottom: 40px
    margin-right: 12px
    margin-left: -12px

.col-it-right
  +v-xs
    margin-right: 12px
    margin-left: -12px
