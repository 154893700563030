.tlp
  display: flex
  justify-content: flex-start
  position: absolute
  top: 0
  left: 90px
  width: 264px
  height: auto
  padding: 16px 16px 0 16px
  border-radius: 16px
  background-color: $white !important
  z-index: 99999
  box-shadow: 0 4px 20px rgba(0,0,0,.15)
  +v-xs
    top: 100%
    left: 0
  &.up
    top: -132px
  &.pink
    border: 2px solid $pink
    span
      background-color: $pink
  &.blue
    border: 2px solid $theme
    span
      background-color: $theme
  &_container
    position: relative
    top: 0
    padding-left: 6px
    width: 360px
    display: flex
    justify-content: flex-start
  span
    display: flex
    justify-content: center
    align-items: center
    min-width: 32px !important
    max-height: 32px !important
    color: $white
    font-size: 24px
    font-weight: 600
    border-radius: 4px
  p
    margin-top: -4px
    padding: 0 16px
    color: $dark
    font-size: 16px
    font-weight: 500
    text-align: left
