// Process

.process
  &_title
    display: flex
    align-items: center
    width: 280px
    height: 48px
    margin-top: 4px
    margin-left: 8px
    padding-left: 16px
    color: $blue
    font-size: 14px
    font-weight: 500
    line-height: 24px
    border-radius: 2px 40px 2px 2px
    background-color: rgba(7,157,221,.16)
    &.pr1
      width: calc(60% - 64px)
    &.pr2
      width: calc(70% - 64px)
    &.pr3
      width: calc(80% - 64px)
    &.pr4
      width: calc(90% - 64px)
    &.pr5
      width: calc(100% - 64px)
