// Margins
.ma
  margin-left: auto !important
  margin-right: auto !important
.mla
  margin-left: auto !important
.mra
  margin-right: auto !important

// Pics
.obfc
  object-fit: cover

  // Width
.w-100
  min-width: 100% !important
.w-90
  min-width: 90% !important
.w-80
  min-width: 80% !important
.w-70
  min-width: 70% !important
.w-60
  min-width: 60% !important
.w-50
  min-width: 50% !important
.w-40
  min-width: 40% !important
.w-30
  min-width: 30% !important
.w-20
  min-width: 20% !important
.w-15
  min-width: 15% !important
.w-10
  min-width: 10% !important
.w-3
  min-width: 33.3333% !important
.w-4
  min-width: 25% !important

// Space
.s-10,
.s-20,
.s-30,
.s-40,
.s-50,
.s-60,
.s-70,
.s-80,
.s-90,
.s-100
  width: 100% !important
  clear: both
  display: block

.s-0
  width: 100% !important
  clear: both
  display: block
  overflow: hidden
  height: 0 !important

.s-10
  height: 10px
.s-20
  height: 20px
.s-30
  height: 30px
.s-40
  height: 40px
.s-50
  height: 50px
.s-60
  height: 60px
.s-70
  height: 70px
.s-80
  height: 80px
.s-90
  height: 90px
.s-100
  height: 100px

.aic
  align-items: center

.aifs
  align-items: flex-start

.aife
  align-items: flex-end

.jcc
  justify-content: center

.jcfs
  justify-content: flex-start

.jcfe
  justify-content: flex-end

.jcsa
  justify-content: space-around

.jcsb
  justify-content: space-between

.center
  justify-content: center

.text-left
  margin: 0
  text-align: left

.text-right
  margin: 0
  text-align: right

.rowrap
  flex-flow: row wrap

.oh
  overflow: hidden

.flex
  display: flex

// Height
.h-100
  height: 100vh

.h-90
  height: 90vh

.h-80
  height: 80vh

.h-70
  height: 70vh

.h-60
  height: 60vh

.h-55
  height: 55vh

.h-50
  height: 50vh

.h-45
  height: 45vh

.h-40
  height: 40vh

.h-30
  height: 30vh

.h-20
  height: 20vh

.h-10
  height: 10vh

// BGs & Colors
.bg-grey
  border-radius: 16px
  background-color: rgba(217,217,217,.5)

.bg-marine
  background-color: $marine !important
.bg-theme
  background-color: $theme !important
.bg-card
  background-color: $card !important
.bg-blue
  background-color: $blue !important

.c-red
  color: $red !important
.c-lite
  color: $lite !important
.c-grey
  color: $grey !important
.c-pink
  color: $pink !important
.c-dark
  color: $dark !important
.c-blue
  color: $blue !important
.c-card
  color: $card !important
.c-theme
  color: $theme !important
.c-marine
  color: $marine !important
.c-white
  color: $white !important
.c-black
  color: $black !important

.medium
  font-weight: 500 !important

.bold
  font-weight: 700 !important

.upp
  text-transform: uppercase
