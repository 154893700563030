.icon
  display: flex
  justify-content: center
  align-items: center
  width: 80px
  height: 80px
  border-radius: 32px
  &.pink
    border: 2px solid $pink
    &.circle
      width: 64px
      height: 64px
  &.blue
    border: 2px solid $blue
    &.circle
      color: $white
      background-color: $blue
  span
    font-size: 24px
    font-weight: 500
    line-height: 18px
  em
    font-size: 12px
    font-weight: 600
    line-height: 18px
